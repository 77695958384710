var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
            name: 'CustomsPriceList',
            params: {}
          })}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1)],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.exportApplyList}},[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v(" "+_vm._s(_vm.$t("export")+_vm.$t("apply_list"))+" ")],1)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container-box.customs-price-manage') + '--' + _vm.container_name}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.end_box",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_end_box(item))+" ")]}},{key:"item.supplier",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_supplier_name(item))+" ")]}},{key:"item.customer",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.commodity.customs_type",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customs_type_name(item))+" ")]}},{key:"item.total_box_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_total_box_amount(item))+" ")]}},{key:"item.total_gross_weight",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_total_gross_weight(item))+" ")]}},{key:"item.customs_price",fn:function(ref){
          var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"save-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"large":""},on:{"save":function($event){return _vm.updateField(item.id,'customs_price',item.customs_price)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{model:{value:(item.customs_price),callback:function ($$v) {_vm.$set(item, "customs_price", $$v)},expression:"item.customs_price"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.customs_price)+" "),_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }