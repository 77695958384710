<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn
          icon
          class="hidden-xs-only"
          @click="
            $router.push({
              name: 'CustomsPriceList',
              params: {}
            })
          "
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          dark
          class="mb-2 ml-2"
          @click="exportApplyList"
        >
          <v-icon>mdi-file-excel</v-icon>
          {{ $t("export")+$t("apply_list") }}
        </v-btn>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container-box.customs-price-manage') + '--' + container_name"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.end_box="{ item }">
          {{ get_end_box(item) }}
        </template>
        <template v-slot:item.supplier="{ item }">
          {{ get_supplier_name(item) }}
        </template>
        <template v-slot:item.customer="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.commodity.customs_type="{ item }">
          {{ get_customs_type_name(item) }}
        </template>
        <template v-slot:item.total_box_amount="{ item }">
          {{ get_total_box_amount(item) }}
        </template>
        <template v-slot:item.total_gross_weight="{ item }">
          {{ get_total_gross_weight(item) }}
        </template>
        <template v-slot:item.customs_price="{ item }">
          <v-edit-dialog
            @save="updateField(item.id,'customs_price',item.customs_price)"
            :save-text="$t('save')"
            :cancel-text="$t('cancel')"
            large
          >
            {{ item.customs_price }} <v-icon>mdi-pencil</v-icon>
            <template v-slot:input>
              <v-text-field v-model="item.customs_price"></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
  <script>
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    url: "/container/container_box/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.code"),
        value: "code",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.start_box"),
        value: "start_box",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.end_box"),
        value: "end_box",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "commodity.name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "commodity.commodity_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "commodity.customs_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.commodity_per_box"),
        value: "commodity_per_box",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_box_amount"),
        value: "total_box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_gross_weight"),
        value: "total_gross_weight",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.customs-price"),
        value: "customs_price",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.total-customs-price"),
        value: "total_customs_price",
      },
      //   {
      //     sortable: false,
      //     text: vm.$i18n.t("actions"),
      //     value: "actions",
      //   },
    ],
    container: null,
    container_name: null,
  }),
  mixins: [BaseTable],
  methods: {
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : "";
    },
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    get_customs_type_name(item) {
      return item.commodity.customs_type
        ? this.getHanText(item.commodity.customs_type, "name")
        : "";
    },
    get_total_box_amount(item) {
      return item.commodity_per_box * item.box_amount;
    },
    get_total_gross_weight(item) {
      return item.gross_weight * item.box_amount;
    },
    exportApplyList(item) {
      const url = "/container/container/" + this.container + "/getApplyList/";
      this.getFileApi(url, "報關清單.xlsx");
    },
  },
  mounted() {
    this.container = this.$route.query.container;
    this.getParams = {
      container: this.container,
    };
    this.container_name = this.$route.query.name;
  },
};
</script>